// Toggle theme
function toggleTheme() {
  const currentTheme = document.documentElement.getAttribute("data-theme");
  const newTheme = currentTheme === "dark" ? "light" : "dark";

  document.documentElement.setAttribute("data-theme", newTheme);
}
const toggleBtn = document.querySelector("#toggleTheme i");
toggleBtn.addEventListener("click", toggleTheme);

// Scorll to top
const scrollToTopButton = document.querySelector(".top");
window.addEventListener("scroll", () => {
  if (window.scrollY > 300) {
    scrollToTopButton.style = "transform: translateX(0)";
  } else {
    scrollToTopButton.style = "transform: translateX(1000px)";
  }
});

scrollToTopButton.addEventListener("click", () => {
  window.scrollTo({
    top: 0,
  });
});
